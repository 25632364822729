<template>
    <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
    >
        <template #button-content>
            <b-img
                :src="currentLocale.img"
                height="14px"
                width="22px"
                :alt="currentLocale.locale"
            />
            <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        </template>
        <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="setLang(localeObj)"
        >
            <b-img
                :src="localeObj.img"
                height="14px"
                width="22px"
                :alt="localeObj.locale"
            />
            <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {BNavItemDropdown, BDropdownItem, BImg} from 'bootstrap-vue'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BImg,
    },
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale)
        },
    },
    methods: {
        setLang(value) {
            this.$i18n.locale = value.locale
            localStorage.setItem('lang', value.locale)
            window.location.reload()
        },
    },
    setup() {
        /* eslint-disable global-require */
        const locales = [
            {
                locale: 'ru',
                img: require('@/assets/images/flags/ru.png'),
                name: 'Русский',
            },
            {
                locale: 'uz',
                img: require('@/assets/images/flags/uz.png'),
                name: 'O`zbekcha',
            },
        ]
        /* eslint-disable global-require */

        return {
            locales,
        }
    },
}
</script>

<style lang="scss">

.dropdown-language .dropdown-menu .dropdown-item {
    padding: 0.65rem 1.28rem;
}

</style>