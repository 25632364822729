import Permission from "@/permission/index";

export default class CardTypePermission extends Permission {
    static cardTypes() {
        return super.getUserPermission('card_types')
    }

    static getCardTypePermission(property) {
        const splitProperty = property.split('.')
        const [one, two] = splitProperty
        if (this.hasAdminRole()) return true
        if (splitProperty.length > 1) {
            return this.cardTypes()[one][two] ?? false
        } else {
            return this.cardTypes()[one] ?? false
        }
    }

    static getCardTypeCreatePermission() {
        return this.getCardTypePermission('create')
    }

    static getCardTypeViewPermission() {
        return this.getCardTypePermission('view')
    }

    static getCardTypeEditPermission() {
        return this.getCardTypePermission('update')
    }

    static getCardTypeDeletePermission() {
        return this.getCardTypePermission('delete')
    }
}
