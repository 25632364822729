/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import home from "@/navigation/vertical/home";
import users from "@/navigation/vertical/users";
import roles from "@/navigation/vertical/roles";
import category from "@/navigation/vertical/category";
// import country from '@/navigation/vertical/country';
// import cards from '@/navigation/vertical/cards';
// import cardType from '@/navigation/vertical/card-type';

// Array of sections
export default [
  {
    header: "sections",
  },
  ...home,
  ...users,
  ...roles,
  {
    header: "knowledge-base",
  },
  ...category,
  // ...cards,
  // ...cardType,
];

// export default filteredNav()
