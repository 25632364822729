<template>
    <layout-vertical>
        <router-view/>
    </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import {$themeConfig} from '@themeConfig'
import ToastNotification from "@core/components/toastification/ToastNotification";
import api from "@/services/api";
// import store from "@/store";

export default {
    components: {
        // AppCustomizer,
        LayoutVertical,
    },
    data() {
        return {
            showCustomizer: $themeConfig.layout.customizer,
        }
    },



    methods: {
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },

    },
}
</script>
