<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <!--        <p class="user-name font-weight-bolder mb-0">-->
                <!--          ФИО-->
                <!--        </p>-->
                <div class="user-status">
                    <div class="user-data">
                        <p class="name">
                            {{ fullName }}
                        </p>
                        <p class="role">
                            {{ roleData.name }}
                        </p>
                    </div>
<!--                    <feather-icon-->
<!--                        size="16"-->
<!--                        :icon="'ChevronDownIcon'"-->
<!--                    />-->
                    <b-avatar>
                    </b-avatar>

                </div>
            </div>
        </template>

        <!--  SETTINGS  -->
        <b-dropdown-item
            :to="{ name: 'settings' }"
            link-class="d-flex align-items-center"
        >
            <feather-icon
                size="16"
                icon="SettingsIcon"
                class="mr-50"
            />
            <span>{{ $t('user_dropdown.settings') }}</span>
        </b-dropdown-item>

        <!--  LOGOUT  -->
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
            <feather-icon
                size="16"
                icon="LogOutIcon"
                class="mr-50"
            />
            <span>{{ $t('logout') }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import {initialAbility} from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import {avatarText} from '@core/utils/filter'
import {removeLocalVar, setLocalVar} from '@/util/localstorage-helper'

import api from "@/services/api";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BAvatar,
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('user')),
            roleData: JSON.parse(localStorage.getItem('role')),
            avatarText,
        }
    },
    computed: {
        fullName() {
            if (this.userData) {
                const {first_name, last_name, second_name} = this.userData
                if (first_name && last_name && second_name) {
                    return first_name + ' ' + last_name + ' ' + second_name
                }
            }
        }
    },
    methods: {
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)


            api.auth.logOut().then(() => {
                removeLocalVar('accessToken')
                removeLocalVar('user')
                removeLocalVar('role')
                this.$router.push({name: 'auth-login'})
                this.showToast('success', 'Успешно!', 'CheckIcon')
            }).catch(() => {
                this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })


            // Reset ability
            // this.$ability.update(initialAbility)

            // Redirect to login page

        },
    },
}
</script>

<style lang="scss">

.dropdown-user .dropdown-menu-right .dropdown-item {
    padding: 0.65rem 1.28rem;
}

.user-status {
    display: flex;
    align-items: center;
    gap: .5rem;

    .user-data {
        display: flex;
        flex-direction: column;
        gap: .25rem;

        p {
            line-height: inherit;
            margin-bottom: 0;
            text-align: right;
        }

        .role {
            font-weight: 600;
        }
    }


}
</style>
