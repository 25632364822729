export default [
  {
    title: "category",
    route: "category",
    icon: "GridIcon",
  },
  {
    title: "department",
    route: "department",
    icon: "ArchiveIcon",
  },
  {
    title: "brand",
    route: "brand",
    icon: "CommandIcon",
  },
  {
    title: "product",
    route: "product",
    icon: "ShoppingBagIcon",
  },
];
